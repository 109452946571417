import { render, staticRenderFns } from "./Browse.vue?vue&type=template&id=8c57f6a6&scoped=true&lang=pug&"
import script from "./Browse.vue?vue&type=script&lang=js&"
export * from "./Browse.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8c57f6a6",
  "09e1ed1b"
  
)

export default component.exports