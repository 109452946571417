import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=0a722dcc&scoped=true&lang=pug&"
import script from "./ProjectCard.vue?vue&type=script&lang=js&"
export * from "./ProjectCard.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ProjectCard.vue?vue&type=style&index=0&id=0a722dcc&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0a722dcc",
  "a2664140"
  
)

export default component.exports