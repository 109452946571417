export const ROUTE_HOME = 'home'
export const ROUTE_BROWSE = 'browse'
export const ROUTE_SEARCH = 'search'
export const ROUTE_NEEDS_HELP = 'needs_help'
export const ROUTE_PROJECT = 'project'

export const MUTATION_PROJECTS = 'set_projects'
export const MUTATION_BROWSE = 'set_browse'
export const MUTATION_CONTENT = 'set_content'
export const MUTATION_SOCKET = 'set_socket'
export const MUTATION_EMIT = 'emit_message'
